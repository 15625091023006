import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContactForm from "./contactForm";

export default function Footer() {
  useEffect(() => {
    console.log("useEffect");
  }, []);

  return (
    <div className="mainFooter">
      <ContactForm />
      <div className="footerBG"></div>
      <div id="contactform-anchor" className="footerContainer1">
        <div className="footer-left-side">
          <ul className="contactList">
            <li>
              <a href="tel:+4796006321">+47 960 06 321</a>
            </li>
            <a
              href="https://www.google.com/maps/place/1+fjordgløttvegen,+porsgrunn,+norway"
              target="_blank"
            >
              Nedre Hjellegate 1, 3724 Skien
            </a>
            <li>
              <a href="mailto:kundeservice@effektivjobb.no">
                kundeservice@effektivjobb.no
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-right-side column">
          <a>© 2023 EffektivJobb</a>
          <a>
            Org nr: 933 582 329 MVA
          </a>
        </div>
      </div>
      <hr className="footerDivider" />
      <div className="footerContainer1">
        <Link className="PersonvernLink" to="/personvern">
          Personvern
        </Link>
        <div className="socialIcons">
          <a className="socialLink" href="www.google.com">
            <InstagramIcon />
          </a>
          <a className="socialLink" href="www.google.com">
            <FacebookIcon />
          </a>
          <a className="socialLink" href="www.google.com">
            <LinkedInIcon />
          </a>
        </div>
      </div>
    </div>
  );
}
