import { useEffect, useRef, useState } from "react";
import TimelineArea, { TimelineCard } from "../components/timelineArea";
import { makeStyles } from "@mui/styles";
import DefaultWrapper from "../components/defaultWrapper";
import { AccessAlarmOutlined, AirplanemodeActiveRounded, ArchiveOutlined, Business, BusinessRounded, CalendarMonthRounded, ConstructionOutlined, DescriptionRounded, FileOpen, HouseRounded, PeopleAltOutlined, Settings, TranslateRounded, ViewModuleRounded } from "@mui/icons-material";

const done = [
    {
        title: "Rapporter",
        description: "Ett lett utførbar rutetabell for å generere rapporter for å sende til kunder eller ledere.",
        icon: FileOpen
    },
    {
        title: "Timeføring",
        description: "En lett å bruke timeføringsmodul som gjør det enkelt å registrere timer for ansatte.",
        icon: AccessAlarmOutlined
    },
    {
        title: "Kunder",
        description: "Ett kundesystem som gjør det enkelt å holde oversikt over kunder og kundeinformasjon.",
        icon: PeopleAltOutlined
    },
    {
        title: "Prosjekter",
        description: "Projektsystem som linkes opp mot kunder for å holde oversikt over prosjekter. Samt Rapporter og timeføring.",
        icon: BusinessRounded
    },
    {
        title: "Utstyr",
        description: "Ett lett utstyrsystem som gjør det enkelt å holde oversikt over utstyr og utstyrsinformasjon.",
        icon: ConstructionOutlined
    },
    {
        title: "Dokumentarkiv",
        description: "Dokumentarkiv per prosjekt for å holde det ryddig og oversiktlig.",
        icon: ArchiveOutlined
    },
    {
        title: "Timeplanlegger",
        description: "Sette opp timer for ansatte som de lett kan gjennomføre og registrere timer på.",
        icon: CalendarMonthRounded
    },
    {
        title: "Oversetter",
        description: "Oversettelse til flere språk for å kunne bruke Effektivjobb på flere språk.",
        beta: true,
        icon: TranslateRounded
    },
]

const notDone = [
    {
        title: "Ferie & timebank",
        description: "Ferie system og timebank for ansatte, lett oversiktlig og enkelt å bruke.",
        //estimated: "Mai 2024",
        icon: AirplanemodeActiveRounded
    },
    {
        title: "HMS",
        description: "HMS system for å holde oversikt over hendelser og rapportere disse. Automatisk arkivering i prosjekter.",
        icon: DescriptionRounded
    },
    {
        title: "Oppdatering av utstyr",
        description: "Oppdatering av utstyr og utstyrsinformasjon.",
        icon: ConstructionOutlined
    },
    {
        title: "Tripletex integrasjon",
        description: "Tripletex integrasjon for å synkronisere data mellom systemene.",
        icon: ViewModuleRounded
    },
]




export default function Timeline() {
    const containerRef = useRef(null);
    const completedRef = useRef(null);
    const [scroll, setScroll] = useState(0);

    const handleScroll = (e) => {
        if (!containerRef?.current) return
        setScroll(containerRef.current.scrollLeft)
    }

    useEffect(() => {
        // listen for scrollwheel event to scroll sideways
        const handleWheel = (e) => {
            e.preventDefault();
            //containerRef.current.scrollLeft += e.deltaY;
            containerRef.current.scrollBy({
                left: e.deltaY > 0 ? e.deltaY + 100 : e.deltaY - 100,
                behavior: 'smooth'
            });
        }
        containerRef.current.addEventListener('wheel', handleWheel);
        return () => {
            containerRef?.current?.removeEventListener('wheel', handleWheel);
        }
    }, [containerRef])

    return (
        <DefaultWrapper>
            <div className="time-line-container row" ref={containerRef} onScroll={handleScroll} >
                <div className="column no-gap" ref={completedRef}>
                    <div className="row time-line-upper">
                        {done.map((item, index) => {
                            if (index % 2 !== 0) return null
                            return <TimelineCard key={index} title={item.title} description={item.description} above={index % 2 === 1} wait={index} scrollIntoView={
                                index === done.length - 1
                            } containerRef={containerRef} active={true} beta={item?.beta} icon={item?.icon} />
                        }
                        )}
                    </div>
                    <div className="time-line-line">
                        <div className="time-line-line-inner" style={{
                            width: scroll + containerRef.current?.offsetWidth / 2 > completedRef.current?.offsetWidth ? completedRef.current?.offsetWidth : scroll + containerRef.current?.offsetWidth / 2,
                            transition: 'width 0.5s'
                        }}
                        ></div>
                    </div>
                    <div className="row time-line-under">
                        {done.map((item, index) => {
                            if (index % 2 === 0) return null
                            return <TimelineCard key={index} title={item.title} description={item.description} above={index % 2 === 1} wait={index} active={true}
                                scrollIntoView={
                                    index === done.length - 1
                                } containerRef={containerRef}
                                beta={item?.beta}
                                icon={item?.icon}
                            />
                        }
                        )}
                    </div>
                </div>

                <div className="column" style={{
                    position: 'relative',
                }}>
                    <h3 style={{
                        position: 'absolute',
                        width: "200px",
                        bottom: '50%',
                        left: 0,
                    }}>Her er vi nå</h3>
                    <div style={{
                        position: 'absolute',
                        width: 2,
                        height: '30%',
                        backgroundColor: 'white',
                        left: -20,
                        top: '50%',
                        borderRadius: 50,
                        transform: 'translateY(-50%)'
                    }}></div>
                </div>

                <div className="column no-gap">
                    <div className="row">
                        {notDone.map((item, index) => {
                            if (index % 2 === 1) return null
                            return <TimelineCard key={index} title={item.title} description={item.description} above={index % 2 === 1} wait={index} active={false} estimated={item?.estimated} icon={item?.icon} />
                        }
                        )}
                    </div>
                    <div className="time-line-line">

                    </div>
                    <div className="row" style={{
                        marginLeft: 350
                    }}>
                        {notDone.map((item, index) => {
                            if (index % 2 !== 1) return null
                            return <TimelineCard key={index} title={item.title} description={item.description} above={index % 2 === 1} wait={index} active={false} estimated={item?.estimated} icon={item?.icon} />
                        }
                        )}
                    </div>
                </div>

            </div>
        </DefaultWrapper>
    );
}
