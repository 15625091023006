import emailjs from 'emailjs-com';

export default function send(information) {
    const serviceID = process.env.REACT_APP_SERVICEID; // Adjust this if not in a React environment
    const templateID = process.env.REACT_APP_EMAILJSTEMPLATEKEY; // Ensure the environment variable is correctly named
    const userID = process.env.REACT_APP_PUBLICKEY;

    console.log('Service ID:', serviceID); // This will help you debug if the variables are being read correctly

    return emailjs.send(serviceID, templateID, information, userID)
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            return "success"
        }, (error) => {
            console.log('FAILED...', error);
            return "fail"
        });
}
