import { useEffect, useState } from "react";

import Header from "../components/header";
import Footer from "../components/footer";
import Body from "../components/body";
import { ScrollRestoration } from "react-router-dom";

export default function Legal() {
  return (
    <div className="app">
      <ScrollRestoration />
      <Header />
      <Personvern />
      <Body />
      <Footer />
    </div>
  );
}


export function Personvern() {
  return (
    <div className="legalContainer">
      <h1> Brukervilkår, personvern og cookies for EffektivJobb. </h1>
      <h2>Aksept av brukervilkårene:</h2>
      <p>Ved å bruke tjenestene til EffektivJobb bekrefter brukeren at han/hun har lest, forstått og akseptert
        alle vilkårene og betingelsene som er beskrevet i disse brukervilkårene. Dersom brukeren ikke godtar
        disse vilkårene, ber vi vedkommende om å avstå fra å bruke tjenestene til EffektivJobb. EffektivJobb
        tilbyr en rekke tjenester som er utformet for å hjelpe deg med å organisere og administrere
        arbeidsoppgavene dine på en effektiv måte. Utforsk de forskjellige funksjonene og verktøyene vi
        tilbyr for å oppnå maksimal produktivitet. Vi benytter tjenester fra Google for å gjøre vårt innhold
        bedre og mer relevant for deg. Det innebærer at vi benytter brukerdata for å tilpasse vår
        markedsføring.</p>
      <h2>Brukerens ansvar:</h2>
      <p>Brukeren forplikter seg til å bruke tjenestene til EffektivJobb på en lovlig og ansvarlig måte. Brukeren
        må ikke misbruke tjenestene ved å sende eller laste opp ulovlig, støtende, truende, ærekrenkende
        eller skadelig innhold. Brukeren er fullt ansvarlig for nøyaktigheten av informasjonen som er gitt
        gjennom tjenestene. Vi deler ikke personlig informasjon med tredjeparter uten samtykke fra
        brukeren, med mindre det er nødvendig for å oppfylle våre tjenester eller pålagt etter gjeldende lov.
        Vi bruker personlig informasjon for å forbedre brukeropplevelsen og tilby skreddersydd innhold og
        tjenester. Informasjonen kan også brukes til å kontakte brukeren ved behov, for eksempel for å
        sende viktig oppdateringer eller administrere kontoen.</p>
      <h2>Immaterielle rettigheter</h2>
      <p>Alt innhold som tilbys gjennom tjenestene til EffektivJobb, inkludert tekst, grafikk, logoer, bilder,
        videoer og programvare, er EffektivJobb sin eiendom eller er lisensiert til oss. Brukeren anerkjenner
        at innholdet er beskyttet av opphavsrett, varemerkerett eller andre immaterielle rettigheter, og at
        brukeren ikke har noen rett til dette innholdet uten uttrykkelig skriftlig samtykke fra EffektivJobb.
        Endringer i brukervilkårene
        EffektivJobb forbeholder seg retten til å endre disse brukervilkårene når som helst. Enhver endring
        vil tre i kraft umiddelbart etter publisering av de oppdaterte vilkårene på EffektivJobbs nettsted. Ved
        å fortsette å bruke tjenestene etter at endringene er gjort, anses brukeren å ha godtatt de
        oppdaterte vilkårene.</p>
      <h2>Personvern:</h2>
      <p>Informasjonen som samles inn av informasjonskapsler, behandles i samsvar med vår
        personvernpolicy. Vi beskytter dine personlige opplysninger og tar dine personvernrettigheter på
        alvor.</p>
      <h2>Brukerveiledning:</h2>
      <p>Velkommen til EffektivJobb! Denne brukerveiledningen er ment å hjelpe deg med å få mest mulig ut
        av vår plattform og retningslinjer for å oppnå produktivitet og suksess i arbeidslivet. Les gjennom
        denne veiledningen nøye før du begynner å bruke tjenestene våre.</p>
      <h2>Registrering og kontoopprettelse:</h2>
      <p>For å begynne å bruke EffektivJobb må du registrere deg og opprette en konto. Følg
        registreringsprosessen nøye og sørg for å oppgi nøyaktige opplysninger. Du er ansvarlig for å
        opprettholde konfidensialiteten til din kontoinformasjon og for all aktivitet som skjer under kontoen
        din. EffektivJobb gjør sitt beste for å sikre plattformens sikkerhet og pålitelighet. Vi implementerer
        bransjestandarder for å beskytte dataene dine. Du har også et ansvar for å beskytte din egen konto
        og data. Brukere skal ikke forsøke å få uautorisert tilgang til andres kontoer eller involvere seg i
        skadelig aktivitet. EffektivJobb kan bruke informasjonskapsler og lignende sporingsteknologi for å
        tilpasse brukeropplevelsen og analysere bruksmønstre. Dette hjelper oss med å forbedre våre
        tjenester og tilby bedre tilpassede anbefalinger.</p>
      <h2>Bruk av EffektivJobb-tjenester:</h2>
      <p>EffektivJobb tilbyr en rekke tjenester som er utformet for å hjelpe deg med å organisere og
        administrere arbeidsoppgavene dine på en effektiv måte. Utforsk de forskjellige funksjonene og
        verktøyene vi tilbyr for å oppnå maksimal produktivitet. Du vil bli bedt om å gi inn noen
        personopplysninger, for eksempel navn, e-postadresse og kontaktdetaljer. Disse opplysningene vil
        bli behandlet i samsvar med gjeldende personvernlover. Sørg for å opprette en sterk og unik
        passordkombinasjon for å beskytte kontoen din. Del ikke passordet ditt med andre.</p>

      <h3>Opphavsrett og rettigheter:</h3>
      <p>EffektivJobb-plattformen og innholdet som tilbys gjennom den, er beskyttet av opphavsrett og andre
        immaterielle rettigheter. Du må ikke kopiere, distribuere eller misbruke innholdet uten den
        nødvendige tillatelsen.

        EffektivJobb tar databeskyttelse og personvern svært alvorligt. All innsamling, bruk og oppbevaring
        av personopplysninger vil bli gjort i henhold til gjeldende lover og forskrifter. Vår policy beskriver
        hvilke typer personlig informasjon vi samler inn, hvordan vi bruker den, og hvordan vi beskytter den.
        Ved å bruke tjenestene til EffektivJobb, samtykker brukeren i at personlig informasjon kan samles
        inn og behandles som beskrevet i personvernpolicyen.
        EffektivJobb er forpliktet til å beskytte personvernet til våre brukere. Denne policyen beskriver
        hvordan vi samler inn, bruker og beskytter personlig informasjon som blir gitt til oss gjennom vår
        nettside og tjenester. Ved å bruke EffektivJobb, godtar du denne personvernpolicyen. Vi samler inn
        personlig informasjon, som for eksempel navn, e-postadresse og annen relevant informasjon, frivillig
        fra våre brukere når de registrerer seg på vår nettside eller bruker våre tjenester. EffektivJobb
        samler inn og behandler personopplysninger i tråd med norsk personvernlovgivning</p>
      <h2>Cookies</h2>
      <p>EffektivJobb er opptatt av personvernet til våre brukere og forpliktet til å beskytte den
        informasjonen vi innhenter og bruker gjennom bruken av informasjonskapsler (cookies). Denne
        Cookie-politikken forklarer hva informasjonskapsler er, hvordan vi bruker dem, og hva dine
        valgmuligheter angående informasjonskapsler er.</p>
      <h3>Hva er informasjonskapsler?</h3>
      <p>Informasjonskapsler er små tekstfiler som plasseres på din enhet når du besøker nettsiden vår. De
        hjelper oss med å forbedre nettsidens funksjonalitet og øke din opplevelse som bruker.
        Informasjonskapsler gjør det mulig for oss å lagre preferanser og tilby tilpasset innhold og annonser
        basert på dine interesser.</p>
      <h3>Hvordan vi bruker informasjonskapsler?</h3>
      <p>Vi bruker informasjonskapsler for følgende formål:
        Nødvendig funksjonalitet: Disse informasjonskapslene er avgjørende for at nettsiden vår og
        tjenestene våre skal fungere korrekt. De tillater deg å navigere på nettsiden og bruke funksjonene på
        en sikker og effektiv måte.</p>
      <h3>Ytelse og analyse: </h3>
      <p>Vi bruker informasjonskapsler for å samle inn informasjon om hvordan besøkende
        bruker nettsiden vår, for å forstå og forbedre nettsidens ytelse og funksjonalitet. Denne
        informasjonen er anonym og brukes utelukkende til statistiske formål.
        Tilpasning og annonsering: Vi bruker informasjonskapsler for å tilpasse innhold og annonser til dine
        interesser og preferanser, både på vår nettside og på eksterne nettsteder. Dette gjør at vi kan levere
        mer relevant og målrettet markedsføring.</p>
      <h2>Dine valg</h2>
      <p>Ved å bruke nettsiden vår, samtykker du i bruken av informasjonskapsler i henhold til denne Cookiepolitikken. Du kan når som helst endre eller trekke tilbake ditt samtykke ved å justere innstillingene
        for informasjonskapsler i nettleseren din. Vær oppmerksom på at hvis du blokkerer eller begrenser
        informasjonskapsler, kan visse deler av nettsiden vår muligens ikke fungere som forventet.
        Informasjon og kontakt
        Dersom du har spørsmål, bekymringer eller ønsker mer informasjon om vår bruk av
        informasjonskapsler, vennligst kontakt oss via [<a href="./personvern#KONTAKTINFORMASJON">kontaktinformasjon</a>].
        Vi oppdaterer jevnlig vår Cookie-politikk for å sikre at den er i samsvar med gjeldende lover og
        reguleringer og for å gjenspeile endringer i vår praksis når det gjelder informasjonskapsler. Siste
        oppdatering av denne Cookie-politikken ble gjort 08.12.2023 </p>
      <h2>SAMTYKKE</h2>
      <p>Ved å bruke Nettsiden samtykker du til bruk av informasjonskapsler i samsvar med denne Cookiepolitikken. Hvis du ikke samtykker, vennligst slutt å bruke Nettsiden. Du kan kontrollere og
        administrere informasjonskapsler gjennom innstillingene i nettleseren din. Du kan blokkere
        informasjonskapsler, slette eksisterende informasjonskapsler og sette preferanser for nye
        informasjonskapsler. Vær oppmerksom på at hvis du blokkerer informasjonskapsler, kan det påvirke
        funksjonaliteten til Nettsiden.</p>
      <h2>Kontakt oss:</h2>
      <p>Hvis du har spørsmål, bekymringer eller tilbakemeldinger angående EffektivJobb-tjenestene eller
        disse retningslinjene, kan du kontakte oss direkte via kontaktinformasjonen som er oppgitt på
        nettsiden vår.
        Vi håper at denne brukerveiledningen klargjør vår tilnærming til informasjonskapsler og bruk av
        EffektivJobb-tjenestene. Hvis du fortsetter å bruke nettsiden vår, antar vi at du aksepterer vilkårene
        og betingelsene beskrevet her.
        Endringer i personvernpolicyen
        EffektivJobb forbeholder seg retten til å oppdatere eller endre denne personvernpolicyen når som
        helst. Eventuelle endringer vil bli kunngjort på vår nettside, og det oppfordres til å gjennomgå
        personvernpolicyen jevnlig.
        Har du spørsmål eller bekymringer angående personvernet eller vår personvernpolicy, vennligst
        kontakt oss på support.</p>

      <h2>Appens Datainnsamling og hva vi bruker</h2>

      {/*
        <h3>Stedsdata Oppsamling</h3>
        <p>
          Vi har en valgfri funksjon, som ikke sender informasjon til en ekstern server eller tredje parti som behandler stedsdata.
          Informasjonen blir på din enhet, og ikke sendt ut. Hvordan vi klarer dette er ved at du får en melding som spør om du er på kordinater.
          Om du er det, så vil mobilen automatisk starte tids-takning i appen. Om du ikke er det, så får man ett varsel istedet om å starte det manuelt.

          Vi spør om både omtrentlig plassering og presis plassering før vi tar denne informasjonen, og er et opt-in system.
        </p>
        */}

      <h3>Personinformasjon</h3>
      <p>
        Vi samler opp personinformasjon. Denne informasjonen
        Personinformasjon er all informasjon som direkte eller indirekte
        kan identifisere en enkeltperson.
      </p>
      <p>
        Dette inkluderer navn, fødselsdato (Valgfritt), telefonnummer (Valgfritt),
        e-postadresse, bilder og bruker ID, som kan knyttes til en bestemt person.

        Personinformasjon er sensitiv informasjon som krever
        beskyttelse og behandling i samsvar med personvernlovgivningen.
      </p>
      <p>
        Vi bruker denne dataen i appen for funkjsonalitet og analyse, samtidig som identifikasjon i appen.

        Analyse: Personinformasjon kan brukes til å analysere hvordan en "bruker" bruker appen, for eksempel hvor mange "brukere" som bruker en funksjon eller krasjrapportering.

        Denne informasjonen KAN bli videresendt til tredjepart, ved personlig bekreftelse fra bruker.
      </p>

      <h3>Meldinger</h3>
      <p>Meldinger er ikke inspisert i 15/07/2024 og er kommunikasjon som brukere selv er ansvarlige for.</p>
      <p>Meldingsgrupper og administrasjon av grupper i firmaer er på firmaets eget ansvar.</p>
      <p>Meldinger er kun samlet for funksjonalitet.</p>

      <h3>Ytelse</h3>
      <p>Vi samler opp informasjon om krashlogger, diagnostikk, andre app ytelse funksjoner.</p>
      <p>Denne informasjonen er bare for internt bruk uten person bekreftelse fra bruker, for å sende det videre til en trejepart for problem-løsning.</p>

      <h3>Filer og dokumenter</h3>
      <p>
        Filer og dokumenter assosiert med firmaet KAN bli sendt til tredjeparti ved forespørsel fra kunde.
      </p>
      <p>
        Filer og dokumenter assosiert med meldinger blir ikke sendt til en tredjepart.
      </p>

      <h3>Appinteraksjoner</h3>
      <p>En god app-funksjonalitet er viktig for å gi en god brukeropplevelse og sikre at appen oppfyller sine formål på en effektiv og brukervennlig måte</p>
      <p>Vi samler appintereasjoner for analyse of funksjonalitet i appen.</p>
      <p>Denne dataen blir bare brukt internt for å bedre produktet vårt.</p>

      <h3>Enhets-id og andre ID-er</h3>
      <p>En enhets-ID er en unik identifikator som tildeles en fysisk enhet, for eksempel en datamaskin, mobiltelefon eller annen enhet. Den brukes til å skille den spesifikke enheten fra andre enheter.</p>
      <p>Vi bruker enhets-id-er for funkjsonalitet i appen, som push notifikasjoner.</p>
      <p>Vi bruker også enhets-id-er for analyse av enheten og funksjonalitet i appen.</p>

      <h2>Sikker håndtering av personlige og sensitive brukerdata</h2>
      <p>Vi forplikter oss til å beskytte dine personlige og sensitive data ved å benytte sikre databehandlingsrutiner. Dette inkluderer, men er ikke begrenset til:</p>
      <h3>Kryptering</h3>
      <p>Alle data overført mellom vår tjeneste og brukeren er kryptert ved hjelp av industristandard sikkerhetsprotokoller.</p>
      <h3>Tilgangskontroll</h3>
      <p>Kun autorisert personell har tilgang til sensitive data. Vi bruker robuste autentiseringsmetoder for å sikre at kun autoriserte brukere kan få tilgang til dataene.</p>
      <h3>Regelmessige sikkerhetsrevisjoner</h3>
      <p> Vi gjennomfører jevnlige sikkerhetsrevisjoner og vurderinger for å sikre at våre databeskyttelsestiltak er oppdaterte og effektive.</p>
      <h3>Overholdelse av lovgivning </h3>
      <p> Vi følger gjeldende lover og forskrifter om personvern og databeskyttelse, inkludert GDPR.</p>

      <h2>Retningslinjer for oppbevaring og sletting av data</h2>
      <p>Vi oppbevarer personopplysninger kun så lenge det er nødvendig for å oppfylle formålene som er beskrevet i denne personvernerklæringen, eller som loven krever. Våre retningslinjer for oppbevaring og sletting av data er som følger:</p>
      <h3>Oppbevaring av data</h3>
      <p>Dine personopplysninger oppbevares så lenge du har en aktiv konto hos oss. Hvis kontoen din er inaktiv i en lengre periode, vil vi varsle deg før vi eventuelt sletter dataene.</p>
      <h3>Sletting av konto</h3>
      <p>Når du velger å slette din konto, vil vi fjerne din personlige data og erstatte dem med en anonym "slettet" bruker. Dette betyr at ditt navn og andre identifiserbare opplysninger vil bli fjernet fra våre systemer, men meldinger og firmadata vil bli beholdt som anonymisert informasjon.</p>

      <h3>Bruk av Firebase</h3>
      <p>Vi benytter Firebase til databehandling. Firebase følger også strenge sikkerhetstiltak for å beskytte dataene dine. For mer informasjon om hvordan Firebase håndterer data, kan du besøke deres personvernerklæring.</p>

      <p>Du kan slette din brukerinformasjon på <a href={"https://app.effektivjobb.no/profile"}>https://app.effektivjobb.no/profile</a>. </p>

      <h2 id="KONTAKTINFORMASJON">KONTAKTINFORMASJON</h2>
      <p>Hvis du har spørsmål eller bekymringer om denne Cookie-politikken eller vår behandling av
        personopplysninger, kan du kontakte oss på følgende måte:</p>
      <p> E-post: <a href="mailto:kundeservice@effektivjobb.no">Kundeservice@effektivjobb.no</a></p>

      <h3>Sist Oppdatert 15/07/2024</h3>
    </div>
  )
}