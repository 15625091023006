import React, { useEffect, useState } from "react";
import { TextField, Box, Stack } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import customThemes from "../components/customThemes";
import send from "../send";
import GoogleMaps from "./googleMaps";
import PrimaryButton from "./primaryButton";

const ContactForm = () => {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    number: "",
    personalMessage: "",
  });

  const [errors, setErrors] = useState({});
  const [sent, setSent] = useState(false);

  // Handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Add validation logic if needed and process the form
    const sentValue = await send(formValues);
    setSent(sentValue);
  };

  // Handle changes in form inputs
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const [render, setRender] = useState(false);
  const renderRef = React.createRef();

  useEffect(() => {
    // Callback for Intersection Observer
    const handleIntersect = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Element is in view, you can perform actions here
          setRender(true);
        }
      });
    };

    // Create Intersection Observer
    const observer = new IntersectionObserver(handleIntersect, {
      root: null, // Use the viewport as the root
      rootMargin: "0px",
      threshold: 1.0, // Adjust threshold as needed
    });

    // Observe the ref
    if (renderRef.current) {
      observer.observe(renderRef.current);
    }

    // Cleanup observer on unmount
    return () => {
      if (renderRef.current) {
        observer.unobserve(renderRef.current);
      }
    };
  }, []);

  if (!render)
    return (
      <div
        onScroll={(e) => {
          setRender(true);
        }}
        ref={renderRef}
      ></div>
    );


  return (
    <ThemeProvider theme={customThemes}>
      <div className="contactContainer">
        <GoogleMaps />
        <Box
          className="contactForm"
          component="form"
          //noValidate
          autoComplete="off"
          onSubmit={handleSubmit}
          sx={{ width: "100%", padding: "0" }}
        >
          {sent !== false &&
            <div className="hasSent">
              {sent === "success" &&
                <div className="relative ">
                  <div className="background-cover success" />
                  <h2 className="sent-message">Takk for din henvendelse!</h2>
                  <p className="sent-message">Vi vil ta kontakt med deg så fort som mulig.</p>
                </div>
              }
              {sent === "error" && <h2 className="contactHeading">Noe gikk galt, prøv igjen senere.</h2>}
            </div>
          }
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="flex-end"
            spacing={2}
            sx={{ padding: "20px" }}
          >
            <h2 className="contactHeading">Send oss en forespørsel</h2>
            <TextField
              label="Navn"
              variant="outlined"
              name="name"
              value={formValues.name}
              onChange={handleInputChange}
              error={!!errors.name}
              helperText={errors.name}
            />
            <TextField
              required
              label="E-post"
              variant="outlined"
              name="email"
              type="email"
              value={formValues.email}
              onChange={handleInputChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              required
              label="Tlf"
              type="tel"
              variant="outlined"
              name="number"
              value={formValues.tel}
              onChange={handleInputChange}
              error={!!errors.tel}
              helperText={errors.tel}
            />
            <TextField
              label="Beskjed"
              variant="outlined"
              name="personalMessage"
              multiline
              rows={5}
              value={formValues.personalMessage}
              onChange={handleInputChange}
            />
            <button className="primary-button" onClick={handleSubmit}>
            <span className="primary-button-text">Send</span>
            </button>
          </Stack>
        </Box>
      </div>
    </ThemeProvider>
  );
};

export default ContactForm;
