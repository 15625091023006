import React, { useState, useEffect } from "react";

export default function TextMorphBox({ text }) {
  const [newtext, setNewText] = useState(text);
  const [oldText, setOldText] = useState(text);

  useEffect(() => {
    console.log(newtext, text);
    setOldText(newtext);
    setNewText(text);
  }, [text]);

  return (
    <div className="textMorphBox">
      <div key={newtext?.length} className="textMorphBoxText morphIn">
        <p>{newtext}</p>
      </div>
      <div key={oldText?.length + 5} className="textMorphBoxText morphOut">
        <p>{oldText}</p>
      </div>
    </div>
  );
}
