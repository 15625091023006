import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";



/*Default styles*/
import "./styles/_typography.scss";
import "./index.css";
import "./styles/default.scss";
import "./styles/buttons.scss";

/*Component styles*/

import "./styles/footer.scss";
import "./styles/hero.scss";
import "./styles/features.scss";
import "./styles/prices.scss";
import "./styles/contactForm.scss";
import "./styles/legal.scss";
import "./styles/header.scss";
import "./styles/timeline.scss";

/*Pages*/
import Home from "./pages/hjem";
//import About from "./pages/omOss";
import Legal, { Personvern } from "./pages/personvern";
import Timeline from "./pages/timeline";

/* function to check if user is on pc for orbitcontrol deactivation*/
export function CheckIfOnPC() {   return window.innerWidth > 800; }

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/personvern",
    element: <Legal />,
  },
  {
    path: "/personvern/no-style",
    element: <Personvern />,
  },
  {
    path: "/timeline",
    element: <Timeline />,
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
