import { createTheme } from "@mui/material/styles";

const customTheme = createTheme({
  // Global Typography settings
  typography: {
    fontFamily: "'Poppins', sans-serif", // Sets the default font family
    fontWeight: 300, // Sets the default font weight
    button: {
      fontWeight: 600, // Sets a custom font weight for all buttons
      // Disables automatic uppercase for buttons
      textTransform: "none",
    },
    fieldset: {
      fontWeight: 300, // Sets a custom font weight for all buttons
      // Disables automatic uppercase for buttons
      textTransform: "none",
    },
  },

  // Component-specific styles
  components: {
    // Styles for Button component
    MuiButton: {
      styleOverrides: {
        root: {
          // Common styles for all button variants
          // Use padding to determine the size of the button
          padding: "8px 16px", // Default padding
          // Default font size, adjust as needed
          fontSize: "1rem",
          // Media queries for responsive adjustments
          "@media (max-width:600px)": {
            // For small screens (e.g., mobile devices)
            fontSize: "0.8rem", // Smaller font size
            padding: "6px 12px", // Smaller padding
          },
          "@media (min-width:601px) and (max-width:960px)": {
            // For medium screens (e.g., tablets)
            fontSize: "0.9rem", // Slightly smaller font size
            padding: "7px 14px", // Adjust padding
          },
          borderRadius: 5, // Sets border radius for all buttons

          width: "fit-content", // Makes button width adjust to content
        },
        outlined: {
          // Specific styles for 'outlined' variant of buttons
          color: "rgba(255, 255, 255, 0.9)",
          borderColor: "rgba(255, 255, 255, 0.9)",
          "&:hover": {
            // Styles for hover state of 'outlined' buttons
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            borderColor: "rgba(255, 255, 255, 0.9)",
          },
        },
        contained: {
          // Specific styles for 'contained' variant of buttons
          color: "rgba(0, 0, 0, 0.9)",
          backgroundColor: "#FBB104",
          "&:hover": {
            // Styles for hover state of 'contained' buttons
            backgroundColor: "#FBB104",
          },
        },
      },
    },

    // Styles for TextField component

    MuiTextField: {
      styleOverrides: {
        root: {
          // Common styles for all text fields
          backgroundColor: "rgba(0, 0, 0, 0.03)",
          borderRadius: "5px",
          width: "100%",

          "& label.Mui-focused": {
            color: "rgba(0, 0, 0, 0.9)", // Color of label when the text field is focused
          },

          "& .MuiOutlinedInput-root": {
            // Styles for outlined variant of text fields
            color: "rgba(0, 0, 0, 0.9)",

            "& fieldset": {
              borderColor: "rgba(0, 0, 0, 0.3)",
            },

            "&:hover fieldset": {
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              borderColor: "rgba(0, 0, 0, 0.5)", // Border color on hover
            },
            "&.Mui-focused fieldset": {
              borderColor: "rgba(0, 0, 0, 0.9)",
              backgroundColor: "rgba(0, 0, 0, 0.0)", // Border color when text field is focused
            },
          },
        },
      },
    },
  },
});

export default customTheme;
