import { ReactComponent as Gradient } from "../assets/gradientBorder.svg";
import { useEffect, useRef, useState } from 'react';

export const TimelineCard = (props) => {
    const containerRef = props.containerRef;
    const cardRef = useRef(null);

    useEffect(() => {
        console.log('scrollIntoView', props.scrollIntoView);
        if (props?.scrollIntoView && containerRef.current) {
            console.log('scrollIntoView', props.scrollIntoView);
            setTimeout(() => {
                cardRef.current.scrollIntoView({
                    behavior: 'smooth',
                    inline: 'center',
                });
            }, 1000);
        }
    }, [props.scrollIntoView, containerRef]);

    return (
        <div ref={cardRef} className={`time-line-card ${props.inView ? 'in-view' : ''}`}>
            {props.above && <div className="time-line-line-up">
                {props?.active &&
                    <div className="time-line-line-up-inner" style={{
                        animationDelay: `${props.inView ? 3 : 1}s`
                    }}
                    ></div>
                }
            </div>}
            <div className="icon-button-container">
                <Gradient className="icon-button-container-gradient" />
                <div className="orange-circle">
                    {props?.icon &&
                        <props.icon
                            className="iconBtn"
                            sx={{ fontSize: { xs: 16, sm: 18, md: 20, lg: 25, xl: 52 } }}
                        />
                    }
                </div>
                <h4 className="featureBtn">{props.title}</h4>
                <p className="featureBtn">{props.description}</p>
                {props?.beta &&
                    <div className="beta-tag">
                        <p>BETA</p>
                    </div>
                }
                {props?.estimated &&
                    <div className="beta-tag">
                        <p>{props?.estimated}</p>
                    </div>
                }
            </div>

            {!props.above && <div className="time-line-line-down">
                {props?.active &&
                    <div className="time-line-line-down-inner" style={{
                        animationDelay: `${props.inView ? 3 : 1}s`
                    }}
                    ></div>
                }
            </div>}
        </div>
    )
}
