import { useEffect, useState } from "react";
import { ReactComponent as Gradient } from "../assets/gradientBorder.svg";

export default function Prices() {
  return (
    <div className="prices-container">
      <div className="price-info">
        <h1 className="price-title-section">Betalingsplaner</h1>
        <hr/>
        <p>
          Sjekk hvilken plan som passer best for deg. Her er det ingen skjulte
          kostnader eller etableringsgebyr - det du ser er det du får!
        </p>
      </div>

      <div className="price-primary">
        <Gradient className="prices-button-container-gradient no-mouse-event" />
        <h3 className="price-title">Månedsplan</h3>
        <h2 className="price">149,-</h2>
        <p className="price-paragraph">Per bruker i måneden</p>
        <hr />
        <p>249,- per admin i måneden</p>
        <hr />
        <p>- Gratis prøveperiode på 1 måned</p>
        <p>- Teknisk support</p>
        <p>Priser er eks. MVA</p>
      </div>
      <div className="price-secondary">
        <Gradient className="prices-button-container-gradient no-mouse-event" />
        <h3 className="price-title">Årsplan</h3>
        <h2 className="price">xxx,-</h2>
        <p className="price-paragraph">Per bruker i måneden</p>
        <hr />
        <p>xxx,- per admin i måneden</p>
        <hr />
        <p>- Gratis prøveperiode på 1 måned</p>
        <p>- Teknisk support</p>
        <p>Priser er eks. MVA</p>
      </div>
    </div>
  );
}

/*
<Button variant="contained" href="#contained-buttons">
Kom i gang
</Button>
*/
