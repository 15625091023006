import { useEffect, useState } from "react";
import Hero from "../components/hero";
import Header from "../components/header";
import Footer from "../components/footer";
import Body from "../components/body";
import Features from "../components/features";
import Prices from "../components/prices";
import { TextureContextProvider } from "../components/phoneCanvas";
import { ScrollRestoration } from "react-router-dom";

export default function Home() {
  return (
    <div className="app">
      <TextureContextProvider>
        <ScrollRestoration />
        <Header />
        <Hero />
        <Body />
        <Features />
        <Prices />
        <Footer />
      </TextureContextProvider>
    </div>
  );
}
