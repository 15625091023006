import VideoPlayer from "./heroVideo";
import { ReactComponent as IosBadge } from "../assets/iosBadge.svg";
import { ReactComponent as AndroidBadge } from "../assets/androidBadge.svg";
import PrimaryButton from "./primaryButton";
import SecondaryButton from "./secondaryButton";
import SmallHero from "../assets/smallHero.jpg"
import { CheckIfOnPC } from "..";

export default function Hero() {
  return (
    <div className="heroContainer">
      {!CheckIfOnPC() ? (
        <img className="hero-image" src={SmallHero} alt="hero"></img>) : (
        <VideoPlayer />
      )}
      <div className="mainCta">
        <h1 className="hero-cta-heading">
          Lei av å kaste bort arbeidsdagen på dårlige applikasjoner?
        </h1>
        <p className="hero-cta-paragraph">
          Trykk her for å oppdage hvordan vår app kan gjøre arbeidsdagen din mer
          produktiv, eller kom rett i gang ved å sende forespørsel
        </p>
        <div className="flexbox-row">
          <PrimaryButton to="#features-anchor" buttonText="Se mer" />
          <SecondaryButton
            to="#contactform-anchor"
            buttonText="Send forespørsel"
          />
        </div>
      </div>
      <div className="badge-container">
        <a href="https://apps.apple.com/no/app/effektivjobb/id6471101614">
          <IosBadge className="badge" />
        </a>

        <a href="https://play.google.com/store/apps/details?id=no.effektivjobb.app">
          <AndroidBadge className="badge" />
        </a>
      </div>
    </div>
  );
}
