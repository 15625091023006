import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  Suspense,
} from "react";
import {
  Canvas,
  useFrame,
  extend,
  useLoader,
  useThree,
} from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import phoneModel from "../phone/scene.gltf";
import { TextureLoader, SRGBColorSpace } from "three";
import wallpaper from "../phone/wallpaper.png";
import { createContext } from "react";
import { Html, useProgress } from "@react-three/drei";
import { gsap } from "gsap";
import { CheckIfOnPC } from "..";
import { Bloom, EffectComposer } from "@react-three/postprocessing";

/* 

visual aid for pointlight positioning

import { Sphere } from '@react-three/drei';


function PointLightWithHelper({ position, color, intensity }) {
  return (
    <group>
      <pointLight position={position} color={color} intensity={intensity} />
      <Sphere args={[0.1, 16, 16]} position={position}>
        <meshBasicMaterial color={color} />
      </Sphere>
    </group>
  );
}
*/

/* Context for managing textures dynamically*/
export const TextureContextCreation = createContext();
export const TextureContextProvider = ({ children }) => {
  const [textureT, setTextureT] =
    useState(wallpaper); /* State for the texture */

  /*Providing the texture state and setter function to children components*/
  return (
    <TextureContextCreation.Provider value={{ textureT, setTextureT }}>
      {children}
    </TextureContextCreation.Provider>
  );
};

/* Extending the Three.js orbit controls into react-three-fiber*/
extend({ OrbitControls });

/*Component for orbit controls*/
const Controls = () => {
  const { camera, gl } = useThree(); /*Accessing the camera and renderer*/
  const controls = useRef(); /*Ref for accessing the orbit controls*/

  useFrame(() =>
    controls.current?.update()
  ); /*Updating the controls each frame*/

  /* The orbitControls JSX element to be used inside the Canvas*/
  return (
    <orbitControls
      ref={controls}
      args={[camera, gl.domElement]}
      enableZoom={false}
      enablePan={false}
      enableRotate={CheckIfOnPC()}
    />
  );
};

/*Component for the 3D model*/
const Model = () => {
  const { textureT, setTextureT } = useContext(
    TextureContextCreation
  ); /*Accessing texture state from context*/
  const gltf = useLoader(GLTFLoader, phoneModel); /*Loading the GLTF model*/
  const ref = useRef(); /*Ref for accessing the model*/
  const [mousePosition, setMousePosition] = useState({
    x: 0,
    y: 0,
  }); /*State for mouse position*/

  /*Loading the textures*/
  const texture = useLoader(TextureLoader, textureT); /*Main texture*/
  const emissiveTexture = useLoader(
    TextureLoader,
    textureT
  ); /*Emissive texture*/

  /*Setting texture properties*/
  texture.flipY = false;
  emissiveTexture.flipY = false;

  /*Effect for applying textures to the model*/
  useEffect(() => {
    gltf.scene.traverse((childFirst) => {
      /*Function call to check if the child is the screen and apply textures*/
      checkIfChildIsScreen(childFirst);
    });
  }, [gltf.scene, texture, emissiveTexture]);
  

  /*Function to check if the child is the screen and apply new materials*/
  function checkIfChildIsScreen(child) {
    if (child.name === "Phonescreen_1") {
      const oldMaterial = child.material;
      const newMaterial = oldMaterial.clone();
      newMaterial.map = texture; /*Applying the main texture*/
      newMaterial.emissiveMap =
        emissiveTexture; /*Applying the emissive texture*/
      newMaterial.emissiveMap.flipY = false;
      newMaterial.emissiveIntensity = 0; /*Setting emissive intensity to 0 (no emission)*/

      child.material = newMaterial; /*Updating the material of the screen*/
      child.material.needsUpdate = true; /*Flagging for update*/
      gsap.fromTo(
        newMaterial.map.offset,
        { x: -1 }, // start from x offset of 1
        {
          x: 0, // animate to x offset of 0
          duration: 0.5, // duration in seconds
          ease: "power2.inOut", // easing function
        }
      );
    }
  }

  /*Function to update mouse position*/
  const updateMousePosition = (e) => {
    setMousePosition({
      x: (e.clientX / window.innerWidth) * 2 - 1,
      y: -(e.clientY / window.innerHeight) * 2 + 1,
    });
  };

  /*Event listener for mouse move*/
  useEffect(() => {
    window.addEventListener("mousemove", updateMousePosition);

    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  /*Using frame loop to animate the model based on mouse movement*/
  useFrame(() => {
    if (ref.current) {
      const lerpFactor = 0.15; /*Smooth interpolation factor*/
      const targetY = Math.atan2(mousePosition.x, 0.3) * 0.2;
      ref.current.rotation.y +=
        lerpFactor * (targetY - ref.current.rotation.y) + 0.45;

      const targetX = Math.atan2(-mousePosition.y, 0.5) * 0.25;
      ref.current.rotation.x += lerpFactor * (targetX - ref.current.rotation.x);
    }
  });

  return (
    <primitive
      ref={ref}
      object={gltf.scene}
      scale={[1, 1, 1]}
      position={[0, 0, 0]}
      rotation={[0, 0, 0]}
    />
  );
};

function Loader() {
  const { progress } = useProgress();
  return (
    <Html center style={{ color: "white" }}>
      {progress} % loaded
    </Html>
  );
}

//Main component for rendering the 3D phone model
function PhoneCanvas() {
  const [hasScrolled, setHasScrolled] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const canvasRef = useRef();

  // Scroll detection
  useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolled) {
        setHasScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasScrolled]);

  return (
    <div
      ref={canvasRef}
      className="phoneCanvas"
      style={{ position: "relative" }}
    >
      <>

        <Canvas camera={{ position: [0, 0, 2], fov: "75" }} style={{}}
          gl={{ antialias: true }} // WebGLRenderer properties
          dpr={[1, 2]} // Handle device pixel ratio for better sharpness
          shadows
        >
          <ambientLight intensity={1} />
          <directionalLight
            shadow-mapSize-width={2048} shadow-mapSize-height={2048}
            color="white"
            intensity={1}
            position={[0, 1, -1]}
          />
          <directionalLight
            shadow-mapSize-width={2048} shadow-mapSize-height={2048}
            color="white"
            intensity={1}
            position={[0, 1, 1]}
          />
          <pointLight position={[-1, 0, 0]} intensity={1}
            shadow-mapSize-width={2048} shadow-mapSize-height={2048}
          />
          <pointLight position={[1, 0, 0]} intensity={1}
            shadow-mapSize-width={2048} shadow-mapSize-height={2048}
          />
          {
            CheckIfOnPC() && (
              <Controls />
            ) /*Only render the orbit controls if on PC*/
          }
          <Suspense fallback={<Loader />}>
            <Model />
          </Suspense>
          <EffectComposer>
            <Bloom luminanceThreshold={0.1} luminanceSmoothing={0.75} height={300} />
          </EffectComposer>
        </Canvas>

        {console.log("Rendering Canvas")}
      </>
    </div>
  );
}

export default PhoneCanvas;
