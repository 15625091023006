import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as Gradient } from "../assets/gradientBorder.svg";

export default function ButtonsFeat(props) {
  useEffect(() => {
    console.log("useEffect");
  }, []);

  return (
    <div
      className="icon-button-container"
      onClick={() => props?.onClick(props.title)}
    >
      <Gradient className="icon-button-container-gradient" />
      <div className="orange-circle">
        <props.icon
          className="iconBtn"
          sx={{ fontSize: { xs: 16, sm: 18, md: 20, lg: 25, xl: 52 } }}
        />
      </div>
      <h4 className="featureBtn">{props.title}</h4>
    </div>
  );
}
