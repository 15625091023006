import React, { useRef, useState } from "react";
import heroVideo from "../assets/heroVideo.mp4";

const VideoPlayer = () => {
  const videoRef = useRef(null);
  const [videoEnded, setVideoEnded] = useState(false);

  const handleVideoEnd = () => {
    setVideoEnded(true);

    if (videoRef.current) {
      const lastFrameTime = videoRef.current.duration - 0.0;
      videoRef.current.currentTime = lastFrameTime;
    }
  };

  return (
    <video
      className="heroVideo"
      ref={videoRef}
      src={heroVideo}
      type="video/mp4"
      onEnded={handleVideoEnd}
      autoPlay={true}
      muted={true}
      loop={false}
      controls={false}
    >
      Your browser does not support the video tag.
    </video>
  );
};

export default VideoPlayer;
