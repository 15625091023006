import bodyImg from "../assets/bodyImg.png";

export default function Body() {
  return (
    <div className="background-container">
      <img src={bodyImg} alt="bodyBG" className="body-image" />
      <div className="blur"></div>
    </div>
  );
}
