import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/mainLogo.svg";
import SecondaryButton from "./secondaryButton";

export default function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const location = useLocation();
  const hamburgerRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // Check if the click occurred outside the menu AND wasn't on the hamburger button
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        hamburgerRef.current !== event.target &&
        !hamburgerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    setIsOpen(false); // Close the menu whenever the route changes
  }, [location.pathname]);

  useEffect(() => {
    console.log("useEffect");
  }, []);

  return (
    <header className="mainHeader">
      <Link className="logoLink" to="/">
        <Logo className="mainLogo" />
        <h2 className="logoText">Effektiv Jobb</h2>
      </Link>
      <div className="row center-column">
        { !isOpen && <SecondaryButton className="onPc" buttonText="Logg inn" to={"https://app.effektivjobb.no/login"} /> }
        <button
          ref={hamburgerRef}
          className={`hamburger-button center-row ${isOpen ? "open" : ""}`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </button>
      </div>
      <div ref={menuRef} className={`menu ${isOpen ? "open" : "closed"}`}>
        <ul>
          <li>
            <Link className="navLink" to="/">
              Hjem
            </Link>
          </li>
          <li>
            <Link className="navLink" to="/timeline">
              Tidslinje
            </Link>
          </li>
          <li>
            <Link className="navLink" to="/personvern">
              Personvern
            </Link>
          </li>
        </ul>
        <div className="loggInnBtn">
          <SecondaryButton buttonText="Logg inn" to={"https://app.effektivjobb.no/login"} />
        </div>
      </div>
    </header>
  );
}
