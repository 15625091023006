import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

/* stylesheets
text: typography.scss
button: buttons.scss
*/

export default function SecondaryButton({ buttonText, to, className }) {
  return (
    <Link className="secondary-button-anchor" to={to}>
      <button className={className + " secondary-button"} aria-label="Secondary button">
        <span className="secondary-button-text">{buttonText}</span>
      </button>
    </Link>
  );
}
