import React, { useState, useEffect, useRef, useContext, Suspense } from "react";
//icons
import AccessAlarmOutlinedIcon from "@mui/icons-material/AccessAlarmOutlined";
import VolunteerActivismOutlinedIcon from "@mui/icons-material/VolunteerActivismOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import MiscellaneousServicesOutlinedIcon from "@mui/icons-material/MiscellaneousServicesOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import ButtonsFeat from "./featureButton";
import { ReactComponent as Gradient } from "../assets/gradientBorder.svg";
import { TextureContextCreation } from "./phoneCanvas";
import kunder from "../assets/screenshots/kunder.png";
import timeføring from "../assets/screenshots/timeføring.png";
import rapport from "../assets/screenshots/rapporter.png";
import utstyr from "../assets/screenshots/utstyr.png";
import chat from "../assets/screenshots/chat.png";
import dokumenter from "../assets/screenshots/dokumenter.png";
import notater from "../assets/screenshots/notater.png";
import hms from "../assets/screenshots/hms.png";
import TextMorphBox from "./textMorphBox";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

import prosjekter from "../assets/screenshots/prosjekter.png";

function changeTextContent(activeButton) {
  switch (activeButton) {
    case "Timeføring":
      return {
        description:
          "Appen lar deg starte, pause og bytte timere for ulike prosjekter. Når du er ferdig kan du sende tidsregistreringer direkte til din nærmeste leder med ett trykk. Timeføring kan også linkes opp med tripletex.",
        img: timeføring,
      };
    case "Dokumenter":
      return {
        description:
          "Hold dokumentene samlet på ett sted under prosjektet dere holder på med. Dette gjør det lett å finne fram på arbeidplassen, eller når du trenger det",
        img: dokumenter,
      };
    case "Prosjekter":
      return {
        description: "Hold oversikt på prosjekter, og hva som skjer. Prosjekter kan også linkes opp med andre ting som firmaer, og andre ting.",
        img: prosjekter,
      };
    case "HMS":
      return {
        description: "Skriv detaljerte hendelsesrapporter rett i appen.",
        img: hms,
      };
    case "Kunder":
      return {
        description:
          "Ingen behov for rolodex når du kan redigere, søke eller legge til kunder rett i appen.",
        img: kunder,
      };
    case "Utstyr":
      return {
        description:
          "For å holde oversikt på utstyr, og hvem som har det har vi en utstyrløsning med skader, kommentarer og annet",
        img: utstyr,
      };
    case "Rapporter":
      return {
        description:
          "Enkel og intuitiv rapportskriving, med mulighet for å legge til relevant kontaktperson, prosjektnummer og utstyr om nødvendig.",
        img: rapport,
      };
    case "Diverse":
      return {
        description:
          "I tillegg til alt dette har vi en egen chat og notater. Appen blir oppdatert stadig med diverse funksjoner som bidrar til bedre arbeidsflyt.",
        img: chat,
      };
  }
}

const listOfFeatures = [
  "Timeføring",
  "Kunder",
  "Prosjekter",
  "HMS",
  "Utstyr",
  "Dokumenter",
  "Rapporter",
  "Diverse",
];

export default function Features(props) {
  const [activeButton, setActiveButton] = useState("Timeføring");
  const [textContent, setTextcontent] = useState(
    changeTextContent(activeButton)
  );
  const [featureArrayAt, setFeatureArrayAt] = useState(0);

  const { textureT, setTextureT } = useContext(TextureContextCreation);

  useEffect(() => {
    handleActiveButton(listOfFeatures[featureArrayAt]);
  }, [featureArrayAt]);

  useEffect(() => {
    setTextcontent(changeTextContent(activeButton));

    console.log("useEffect");
  }, [activeButton]);

  useEffect(() => {
    if (!textContent?.img) return;
    setTextureT(textContent?.img);
  }, [textContent]);

  function handleActiveButton(title) {
    setActiveButton(title);
  }

  function renderPaginationDots() {
    return (
      <div className="pagination-dots">
        {listOfFeatures.map((_, index) => (
          <span
            key={index}
            className={index === featureArrayAt ? "dot-active" : "dot"}
          />
        ))}
      </div>
    );
  }

  const LazyPhoneCanvas = React.lazy(() => import('./phoneCanvas'));


  return (
    <div id="features-anchor" className="featuresContainer">
      <div className="featInfo">
        <ButtonsFeat
          title="Timeføring"
          icon={AccessAlarmOutlinedIcon}
          onClick={handleActiveButton}
        />
        <ButtonsFeat
          title="Kunder"
          icon={PeopleAltOutlinedIcon}
          onClick={handleActiveButton}
        />
        <ButtonsFeat
          title="Prosjekter"
          icon={FormatListBulletedOutlinedIcon}
          onClick={handleActiveButton}
        />
        <ButtonsFeat
          title="HMS"
          icon={VolunteerActivismOutlinedIcon}
          onClick={handleActiveButton}
        />
        <ButtonsFeat
          title="Utstyr"
          icon={ConstructionOutlinedIcon}
          onClick={handleActiveButton}
        />
        <ButtonsFeat
          title="Dokumenter"
          icon={ArchiveOutlinedIcon}
          onClick={handleActiveButton}
        />
        <ButtonsFeat
          title="Rapporter"
          icon={ArticleOutlinedIcon}
          onClick={handleActiveButton}
        />
        <ButtonsFeat
          title="Diverse"
          icon={MiscellaneousServicesOutlinedIcon}
          onClick={handleActiveButton}
        />
        <div className="feature-description-container">
          <Gradient className="icon-button-container-gradient" />
          <h1 className="feature-description-title">{activeButton}</h1>
          <hr />
          <TextMorphBox text={textContent?.description} />
        </div>
      </div>
      <div className="feature-image-container" style={{ position: "relative" }}>
        <div className="mobile-controls">

          <ArrowBackIosRoundedIcon
            style={{ color: "white", zIndex: 1, fontSize: "3rem" }}
            onClick={() => {
              const newIndex = featureArrayAt === 0 ? listOfFeatures.length - 1 : featureArrayAt - 1;
              setFeatureArrayAt(newIndex);
              if (featureArrayAt === 0) {
                setFeatureArrayAt(listOfFeatures.length - 1);
              } else {
                setFeatureArrayAt(featureArrayAt - 1);
              }
              handleActiveButton(listOfFeatures[featureArrayAt]);
            }}
          />
          <ArrowForwardIosRoundedIcon
            style={{ color: "white", zIndex: 1, fontSize: "3rem" }}
            onClick={() => {
              const newIndex = featureArrayAt === listOfFeatures.length - 1 ? 0 : featureArrayAt + 1;
              setFeatureArrayAt(newIndex);
              if (featureArrayAt === listOfFeatures.length - 1) {
                setFeatureArrayAt(0);
              } else {
                setFeatureArrayAt(featureArrayAt + 1);
              }
              handleActiveButton(listOfFeatures[featureArrayAt]);
            }}
          />

        </div>
        <Suspense fallback={<div className="phoneCanvas">Loading...</div>}>
          <LazyPhoneCanvas />
        </Suspense>
        {renderPaginationDots()}
      </div>

    </div>
  );
}
