import Footer from "./footer";
import Header from "./header";




export default function DefaultWrapper(props) {
    return (
        <div className="default-wrapper">
            <Header />
            {props.children}
            
        </div>
    );
}