import { LocationOn } from "@mui/icons-material";
import React from "react";
//import GoogleMapReact from "google-map-react";
import { useEffect, useState, useRef } from "react";
//import { GoogleMap } from '@capacitor/google-maps';
//import { useSpring, animated } from 'react-spring';
//import { ReactComponent as EditIcon } from '../../assets/icons/material-symbols_edit.svg';



// Define the marker style
export function MyMap(props) {
  const mapRef = useRef(null);
  const [mapInstance, setMapInstance] = useState(null);
  //const expand = useSpring({ height: props.lat && props.lng ? `400px` : '0px', maxHeight: '400px' });
  const wrapperRef = useRef(null);
  const [mapPixels, setMapPixels] = useState("600px");

  useEffect(() => {
    if (!wrapperRef.current) return;
    setMapPixels(getComputedStyle(wrapperRef.current).width)

    const initializeMapAndAddMarker = async () => {
      const mapOptions = {
        center: {
          lat: 59.2072368, //9°36'25.3"E
          lng: 9.6069134
        },
        zoom: 7,
        disableDefaultUI: true, // This disables the default UI controls
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        styles: mapStyling,


      };
      const map = new window.google.maps.Map(document.getElementById('map'), mapOptions);

      console.log("Map should be ready!")

      // Add marker immediately after initializing the map
      // Create a marker and set its position and map
      new window.google.maps.Marker({
        position: {
          lat: 59.2072368, //9°36'25.3"E
          lng: 9.6069134
        },
        map: map,
        title: 'EffektivJobb',
        // make marker orange
        label: {
          text: 'EffektivJobb',
          color: 'white',
          border: '1px solid black',
          fontWeight: 'bold',
          fontSize: '16px',
        },
        icon: {
          path: "M10.0003 13.3333C9.11627 13.3333 8.26842 12.9821 7.6433 12.357C7.01818 11.7319 6.66699 10.8841 6.66699 10C6.66699 9.11595 7.01818 8.2681 7.6433 7.64298C8.26842 7.01786 9.11627 6.66667 10.0003 6.66667C10.8844 6.66667 11.7322 7.01786 12.3573 7.64298C12.9825 8.2681 13.3337 9.11595 13.3337 10C13.3337 10.4377 13.2474 10.8712 13.0799 11.2756C12.9124 11.68 12.6669 12.0475 12.3573 12.357C12.0478 12.6666 11.6804 12.9121 11.2759 13.0796C10.8715 13.2471 10.4381 13.3333 10.0003 13.3333ZM10.0003 0.666672C7.52497 0.666672 5.151 1.65 3.40066 3.40034C1.65032 5.15068 0.666992 7.52465 0.666992 10C0.666992 17 10.0003 27.3333 10.0003 27.3333C10.0003 27.3333 19.3337 17 19.3337 10C19.3337 7.52465 18.3503 5.15068 16.6 3.40034C14.8496 1.65 12.4757 0.666672 10.0003 0.666672Z",
          scale: 1,
          fillColor: "#FF9D14",
          fillOpacity: 1,
          strokeWeight: 0,
          strokeColor: "#FF9D14",
          anchor: new window.google.maps.Point(20, 20),
          labelAnchor: new window.google.maps.Point(20, 20),
          labelOrigin: new window.google.maps.Point(10, 40),
        },
        //anchorPoint: new window.google.maps.Point(0, -290) // Shift the marker to align the label with the icon    

      });

    }

    //if (props.lat !== 0 && props.lng !== 0) {
    initializeMapAndAddMarker();
    //}
  }, [wrapperRef]);

  useEffect(() => {
    console.log(getComputedStyle(wrapperRef.current).width)
  }, [wrapperRef?.current?.clientWidth])


  return (
    <div className="component-wrapper not-on-mobile" ref={wrapperRef} style={{
      width: '100%',
      overflow: 'hidden',
    }}>
      <div id="map" style={{
        width: mapPixels,
        height: '100%',
      }}>

      </div>
    </div>
  );
}

export default MyMap;

const mapStyling = [
  // Darken the overall map elements
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#212121", // Dark background for the map
      },
    ],
  },
  // Hide icons to keep the map clean
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  // General settings for text
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ffffff", // White text for better contrast
      },
    ],
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#171A21", // Dark color for text strokes, matching water color
      },
    ],
  },
  // Style administrative areas
  {
    featureType: "administrative",
    elementType: "geometry",
    stylers: [
      {
        color: "#2c2c2c", // Slightly lighter shade for administrative areas
      },
    ],
  },
  // Style roads
  {
    featureType: "road",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#FBB104", // Road color
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        color: "#FBB104",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#FBB104",
      },
    ],
  },
  {
    featureType: "road.highway.controlled_access",
    elementType: "geometry",
    stylers: [
      {
        color: "#FBB104",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ffffff", // White text for local road labels
      },
    ],
  },
  // Style water bodies
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#171A21", // Water color
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#ffffff", // White text for water labels
      },
    ],
  },
  // Additional styling can be added here as needed
];
