import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function PrimaryButton({ buttonText, to }) {
  return (
    <Link className="primary-button-anchor" to={to}>
      <button className="primary-button" aria-label="button">
        <span className="primary-button-text">{buttonText}</span>
      </button>
    </Link>
  );
}
